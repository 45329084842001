import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    usuario: sessionStorage.getItem('usuario') ? JSON.parse(sessionStorage.getItem('usuario')) : null,
    usuarioEmail: 
    sessionStorage.getItem('usuarioEmail') ? JSON.parse(sessionStorage.getItem('usuarioEmail')) : null,
    conteudoEnvioNewsletter: []
  },

  getters:{
    getUsuario: state =>{
      return state.usuario;
    },
    getUsuarioEmail: state =>{
      return state.usuarioEmail;
    },    
    getToken: state =>{
      return state.usuario.access_token;
    },
    getConteudoEnvioNewsletter: state =>{
      return state.conteudoEnvioNewsletter;
    }, 
  }, 
  
  mutations: {
    setUsuario(state, n){
      state.usuario = n;
    },
    setUsuarioEmail(state, n){
      state.usuarioEmail = n;
    },        
    setConteudoEnvioNewsletter(state, n) {
      state.conteudoEnvioNewsletter = n;
    }, 
  },

  actions: {
  },

  modules: {
  }
})
