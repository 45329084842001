import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [

  {
    path: '/home',
    name: 'Home',
    component: function (){
      return import('../views/Home.vue')
    }
  },   
  
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/About.vue')
    }
  },
    {
      path: '/',
      name: 'Login',
      component: function (){
        return import('../views/Login.vue')
      }
    },
    
    {
      path: '/verify',
      name: 'Verify',
      component: function (){
        return import('../views/Verify.vue')
      }
    }, 
    
    {
      path: '/ti',
      name: 'TI',
      component: function (){
        return import('../views/TI.vue')
      }
    },     

    {
      path: '/direcao',
      name: 'Direcao',
      component: function (){
        return import('../views/Direcao.vue')
      }
    },

    {
      path: '/comercial',
      name: 'Comercial',
      component: function (){
        return import('../views/Comercial.vue')
      }
    },

    {
      path: '/indique/listar',
      name: 'ListarIndicados',
      component: function (){
        return import('../views/indique/Listar.vue')
      }
    }, 
    
    {
      path: '/indique/ranking',
      name: 'Ranking',
      component: function (){
        return import('../views/indique/Ranking.vue')
      }
    }, 
    
    {
      path: '/indique/ver/:id',
      name: 'VerIndicados',
      component: function (){
        return import('../views/indique/Ver.vue')
      }
    },     

    {
      path: '/newsletter/criar',
      name: 'Criar',
      component: function (){
        return import('../views/newsletter/Criar.vue')
      }
    },

    {
      path: '/newsletter/ver/:id/:assunto?',
      name: 'Ver',
      component: function (){
        return import('../views/newsletter/Ver.vue')
      }
    },
    
    {
      path: '/newsletter/listar',
      name: 'Listar',
      component: function (){
        return import('../views/newsletter/Listar.vue')
      }
    },      
  
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
