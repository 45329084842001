import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// eslint-disable-next-line no-unused-vars
import axios from 'axios'
import GAuth from 'vue-google-oauth2'
import Vuex from 'vuex'
Vue.use(Vuex)

Vue.config.productionTip = false
Vue.prototype.$http = axios
//Vue.prototype.$urlApi = 'http://192.168.15.10:8000/api/'
Vue.prototype.$urlApi = 'https://donaclara.com.br/l7.0/api/v1/'
//Vue.prototype.$urlApi = 'http://127.0.0.1:8000/api/'
Vue.prototype.$urlApiImg = 'https://api.imgur.com/3/image'
Vue.prototype.$urlGApi = 'https://admin.googleapis.com/admin/directory/v1/groups/'



//Vue.prototype.$Api = 
//'http://colegiodonaclaraltda.rm.cloudtotvs.com.br:8051/api/framework/v1/consultaSQLServer/RealizaConsulta/'

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import './assets/css/style.css'

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

Vue.component('pagination', require('laravel-vue-pagination'));


//import { VueEditor } from "vue2-editor";
//Vue.use (VueEditor)
//Vue.config.productionTip = false

Vue.directive('scroll', {
  inserted: function (el, binding) {
    let f = function (evt) {
      if (binding.value(evt, el)) {
        window.removeEventListener('scroll', f)
      }
    }
    window.addEventListener('scroll', f)
  }
})


new Vue({
  router,
  store,
  render: function (h) { return h(App) }
}).$mount('#app')

const gauthOption = {
  clientId: '902141085397-9bil33vnqijp0f3rq3dthhe6ml6oia0e.apps.googleusercontent.com',
  scope: 'openid profile email https://www.googleapis.com/auth/admin.directory.group https://www.googleapis.com/auth/admin.directory.group.member https://www.googleapis.com/auth/admin.directory.user.security',
  prompt: 'select_account'
}

Vue.use(GAuth, gauthOption)
